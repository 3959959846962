import React from 'react'

const LoadSpinnerComponent = () => {
  return (
    <div style={{ backgroundColor: "#110f12" }}>
      <div className="lds-default">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default LoadSpinnerComponent